import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 border-2 w-full mb-3 text-black`}
  }
  textarea {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 border-2 w-full mb-3 text-black`}
  }
  button {
    ${tw`w-full bg-primary-300 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

{
  /* <NavLink href="#Services">Services</NavLink>
<NavLink href="#Quality work">Quality work</NavLink>
<NavLink href="#Get started">Get started</NavLink>
<NavLink href="#Our Values">Our Values</NavLink>
<NavLink href="#Contact Us">Contact Us</NavLink> */
}

export default () => {
  const formRef = useRef();

  const initialFormState = { name: "", email: "", message: "" };
  const [form, setForm] = useState(initialFormState);
  const [formButtonText, setFormButtonText] = useState("Send");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it
    setForm(initialFormState);
    setFormButtonText("Sending...");
    emailjs
      .sendForm(
        "service_jlap0y6",
        "template_09vl8wf",
        formRef.current,
        "M2f3moA5Oy6E2Shnm"
      )
      .then(
        () => {
          // btn.value = "Send Email";
          console.log("Sent!");
          setFormButtonText("Sent!");
          setForm(initialFormState); // clear form fields
          setInterval(() => {
            setFormButtonText("Send");
          }, 3000);
        },
        (err) => {
          // btn.value = "Send Email";
          console.log(JSON.stringify(err));
        }
      );
  };

  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#Get started">Get started</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#Contact Us">Contact Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>What we offer</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#Services">Services</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#Quality work">Quality work</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#Our values">Our values</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Address</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <p>Simple Code-X Ltd</p>
                <p>Deansgate</p>
                <p>Manchester</p>
                <p>M3 4EL</p>
                <b>
                  <a href="mailto:accounts@simple-code-x.com">
                    accounts@simple-code-x.com
                  </a>
                </b>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Message Us</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Actions>
                  <form
                    className="contact-form"
                    onSubmit={sendEmail}
                    ref={formRef}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={form.name}
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={form.email}
                      onChange={handleChange}
                    />
                    <label>Message</label>
                    <textarea
                      name="message"
                      onChange={handleChange}
                      value={form.message}
                    />
                    <button type="submit" onClick={sendEmail}>
                      {formButtonText}
                    </button>
                  </form>
                </Actions>
              </LinkListItem>

              <LinkListItem>
                {/* <p>Speak to us today for day rates and general quotes.</p> */}
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Simple Code-X Ltd</LogoText>
          </LogoContainer>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} Simple Code-X Ltd. All Rights
            Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://linkedin.com/company/simple-code-x-limited"
              target="new"
            >
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
