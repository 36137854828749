import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import CustomersLogoStripImage from "../../images/all-logos.png";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import { ReactComponent as FrontEndIcon } from "feather-icons/dist/icons/layout.svg";
import { ReactComponent as BackEndIcon } from "feather-icons/dist/icons/database.svg";
import { ReactComponent as CloudIcon } from "feather-icons/dist/icons/cloud.svg";
import { ReactComponent as AiIcon } from "feather-icons/dist/icons/cpu.svg";
import { ReactComponent as SecurityIcon } from "feather-icons/dist/icons/shield.svg";
import { ReactComponent as SupportIcon } from "feather-icons/dist/icons/compass.svg";

const Container = tw.div`relative bg-primary-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-300 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full`}
  }
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "Experience unmatched quality and security with Simple Code-X's comprehensive services, combining robust development, proactive support, and advanced AI solutions",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: FrontEndIcon,
      title: "Frontend",
      description:
        "Creating user-friendly, responsive, fast interfaces with React, Angular, JavaScript, TypeScript for a seamless experience across all devices.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Backend",
      description:
        "Delivering robust, scalable solutions with efficient data management through Node.js, .NET to handle high traffic and large data volumes",
    },
    {
      imageSrc: FastIconImage,
      title: "Cloud",
      description:
        "Providing scalable, cost-effective cloud solutions on AWS, GCP, Azure for easy resource management and business adaptability",
    },
    {
      imageSrc: ReliableIconImage,
      title: "AI",
      description:
        "Leveraging advanced AI technologies for intelligent automation, predictive analytics, and personalization, enhancing business efficiency and customer experience.",
    },
    {
      imageSrc: ShieldIconImage,
      title: "Security",
      description:
        "Prioritizing top-notch security measures for data protection against breaches and threats, employing best practices for secure coding.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Support",
      description:
        "Offering 24/7 support and proactive monitoring to ensure smooth operations, rapid problem resolution, and continual system optimization",
    },
  ];

  const getIcons = (name) => {
    switch (name) {
      case "Frontend":
        return <FrontEndIcon />;
      case "Backend":
        return <BackEndIcon />;

      case "Cloud":
        return <CloudIcon />;

      case "AI":
        return <AiIcon />;

      case "Security":
        return <SecurityIcon />;
      case "Support":
        return <SupportIcon />;
      default:
        break;
    }
  };

  if (!cards) cards = defaultCards;

  return (
    <Container id="Services">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">{getIcons(card.title)}</span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}

        <CustomersLogoStrip>
          <p>Some of our Clients</p>
          <img src={CustomersLogoStripImage} alt="Our Customers" width="150%" />
        </CustomersLogoStrip>
      </ThreeColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};
